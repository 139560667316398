<section
  [ngClass]="isSticky === true ? 'sticky d-md-none' : 'd-none'"
  *ngIf="productEntries?.length > 0"
>
  <div
    class="savings-total d-flex flex-wrap justify-content-center gap-3 align-items-end mx-n3 mx-sm-n4 mt-4 mt-sm-3 p-3 box-shadow-bottom u-background--white"
  >
    <h3 class="c-heading c-heading-h3 m-0">
      {{ 'addToCartItems.orderSubtotal' | cxTranslate }}
      <span *ngIf="!updateTotal; else noPrice"
        >{{ standardCartDetails?.subTotal?.value | currency }}
      </span>
      <ng-template #noPrice>
        {{ 'addToCartItems.noprice' | cxTranslate }}
      </ng-template>
    </h3>
    <div class="d-flex align-items-center">
      <button
        *ngIf="!updateTotal"
        type="button"
        class="c-button c-button--primary mt-0 mt-sm-2 proceedCheckout"
        (click)="proceedToCheckout()"
        [disabled]="orderBlock || checkoutClicked || yfEntry"
        [ngClass]="orderBlock || yfEntry ? 'disableCTA' : ''"
      >
        <i class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></i>
        {{ 'addToCartItems.proceedToCheckout' | cxTranslate }}
      </button>
      <button
        *ngIf="updateTotal"
        class="c-button c-button--secondary mt-0 mt-sm-2 updateOrder"
        (click)="updateCart()"
      >
        {{ 'addToCartItems.updateOrder' | cxTranslate }}
      </button>
    </div>
  </div>
</section>

<section class="no-print container mb-3">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-right mb-2 mb-sm-0">
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        href="/CAD/Open-Catalogue/c/1"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}
      </a>
    </div>
    <div
      *ngIf="productEntries?.length > 0"
      class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <ng-template #popSaveTitle>{{
        'addToCartItems.saveOrderTitle' | cxTranslate
      }}</ng-template>
      <ng-template #popSaveContent>
        <input
          type="text"
          class="save-order"
          required
          #name="ngModel"
          [(ngModel)]="saveCartName"
          (keypress)="omit_special_char($event)"
          maxlength="30"
        />
        <span *ngIf="name.errors?.required" class="common-error required">{{
          errorMsg
        }}</span>
        <span class="common-error required">{{ errorMsg }}</span>
        <div class="c-popover">
          <button
            type="reset"
            class="popover-close popover-cancel-button c-button c-button--secondary"
            (click)="toggle(p2)"
          >
            {{ 'addToCartItems.cancel' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="popover-cancel-button c-button c-button--primary"
            data-di-id="di-id-3064f17d-e97cc628"
            (click)="saveOrder()"
          >
            {{ 'addToCartItems.save' | cxTranslate }}
          </button>
        </div>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popSaveContent"
        [popoverTitle]="popSaveTitle"
        popoverClass="popover-links saveorder-incart"
        triggers="manual"
        placement="top"
        container="body"
        #p2="ngbPopover"
        href="javascript:;"
        (click)="toggle(p2)"
      >
        <span>{{ 'addToCartItems.saveOrder' | cxTranslate }}</span>
        <fa-icon [icon]="faRectangleList" class="icon"></fa-icon>
      </a>
      <ng-template #popTitle
        ><span> {{ 'addToCartItems.clearTitle' | cxTranslate }}</span>
      </ng-template>
      <ng-template #popContent>
        <div class="c-popover">
          <button
            type="reset"
            class="popover-close popover-cancel-button c-button c-button--secondary"
            data-di-id="di-id-d01008be-fb6e135c"
            (click)="toggle(p1)"
          >
            {{ 'addToCartItems.cancel' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="popover-cancel-button c-button c-button--primary"
            data-di-id="di-id-3064f17d-e97cc628"
            (click)="clearChildCart()"
          >
            {{ 'addToCartItems.clearCart' | cxTranslate }}
          </button>
        </div>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popContent"
        [popoverTitle]="popTitle"
        popoverClass="popover-links"
        triggers="manual"
        placement="top"
        container="body"
        #p1="ngbPopover"
        href="javascript:;"
        (click)="toggle(p1)"
      >
        <span>{{ 'addToCartItems.clearCart' | cxTranslate }}</span>
        <fa-icon [icon]="faTrashCan" class="icon"></fa-icon>
      </a>
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="exportXls()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>

<div *ngIf="deleteSuccess !== ''" class="global-alerts">
  <div class="c-alert-banner alert-info alert-dismissable getAccAlert">
    <button
      (click)="closeAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    <span class="delete-succ" [innerHTML]="deleteSuccess"></span
    >{{ 'addToCartItems.deleteSuccessMsg' | cxTranslate }}
  </div>
</div>

<div *ngIf="addSuccess" class="global-alerts">
  <div class="c-alert-banner alert-info">
    <button
      (click)="closeAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    {{ 'addToCartItems.addProdSuccess' | cxTranslate }}
  </div>
</div>

<div
  *ngIf="orderBlock && showDangerAlert && productEntries?.length > 0"
  class="global-alerts"
>
  <div class="alert alert-danger alert-dismissable getAccAlert">
    <button
      (click)="closeDangerAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    {{ 'addToCartItems.orderBlockMsg' | cxTranslate }}
  </div>
</div>

<section
  class="container c-prod-details mb-0 mb-sm-3"
  *ngIf="productEntries?.length > 0; else emptyCart"
>
  <section class="row checkout u-background--white" id="leftCont-rightNav-cart">
    <!-- Left side -->
    <div
      class="col-xs-12 col-sm-12 col-md-8 col-lg-8 float-left u-background--white pb-4 pt-4 pt-md-4 pb-md-4"
      style="min-height: 357px"
    >
      <!-- Deliver Option -->
      <section
        class="col-md-12 Fields-container delivary_ord u-border-bottom-grey"
      >
        <section class="delivary_opt">
          <section class="row radio-section w-100">
            <label class="mt-1">{{ 'account.SelectOption' | cxTranslate }}:</label>
            <section class="custom-control custom-radio radio_grp1">
              <input
                id="delivery"
                type="radio"
                value="delivery"
                name="deliveryPickup"
                class="custom-control-input ng-untouched ng-pristine ng-valid"
                ng-reflect-value="delivery"
                ng-reflect-name="deliveryPickup"
                ng-reflect-model="delivery"
                checked="checked"
              /><label for="delivery" class="custom-control-label"
                >{{ 'account.Delivery' | cxTranslate }}</label
              >
            </section>
            <section class="custom-control custom-radio radio_grp1">
              <input
                id="pickup"
                type="radio"
                value="pickup"
                name="deliveryPickup"
                disabled=""
                class="custom-control-input ng-untouched ng-pristine"
                ng-reflect-value="pickup"
                ng-reflect-name="deliveryPickup"
                ng-reflect-is-disabled=""
                ng-reflect-model="delivery"
              /><label for="pickup" class="custom-control-label disableCTA"
                >{{ 'account.Pickup' | cxTranslate }}</label
              >
            </section>
          </section>
        </section>
      </section>

      <!-- Shipping / Calendar -->
      <div class="col-md-12 pl-0 mb-4">
        <div class="row">
          <div class="col-md-7">
            <h3 class="mb-3 font-bold">
              {{ 'addToCartItems.shipLocation' | cxTranslate }}
            </h3>
            <div>
              <i class="fa fa-map-marker-alt font-n"></i
              ><span class="px-3 text-fixed-medium"
                >{{ selectedData?.companyName }}
                {{ '(' + selectedData?.unitID + ')' }}</span
              >
            </div>
            <div class="pl-4">
              <p class="mb-2 text-fixed-medium">
                {{ selectedData?.line1 ? selectedData?.line1 + ', ' : ''
                }}<br *ngIf="selectedData?.line1" />
                {{ selectedData?.line2 ? selectedData?.line2 + ', ' : ''
                }}<br *ngIf="selectedData?.line2" />
                {{ selectedData?.town ? selectedData?.town + ', ' : ''
                }}<br *ngIf="selectedData?.town" />
                {{
                  selectedData?.region?.isocodeShort
                    ? selectedData?.region?.isocodeShort + ' '
                    : ''
                }}
                {{ selectedData?.postalCode }}
              </p>
            </div>
            <div class="pl-3">
              <span
                role="button"
                *ngIf="changeLocation"
                (click)="open(content)"
                class="c-link ml-1"
                >{{ 'addToCartItems.ChangeLocation' | cxTranslate }}</span
              >
            </div>
            <div class="firstLevel-acc cartShipToHeader">
              <section class="col-sm-7 col-md-7 col-lg-7 locleft">
                <section class="ship-address">
                  <section>
                    <section class="checkout-modal">
                      <section class="modal-dialog">
                        <section class="modal-content">
                          <ng-template #content let-modal>
                            <section class="checkout-modal modal-selector">
                              <section class="modal-dialog">
                                <section class="modal-content">
                                  <div class="close">
                                    <i
                                      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                                      (click)="modal.dismiss('Cross click')"
                                    ></i>
                                  </div>
                                  <section
                                    class="modal-header cart-Modal-Header"
                                  >
                                    <h2
                                      class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                                      id="modal-basic-title"
                                    >
                                      {{
                                        'account.selectLocation' | cxTranslate
                                      }}
                                    </h2>
                                  </section>
                                  <section class="modal-body">
                                    <section class="input-group loc-SearchBar">
                                      <div class="c-text-input">
                                        <cx-icon
                                          class="cx-icon fas fa-search"
                                        ></cx-icon>
                                        <input
                                          type="text"
                                          #clientName
                                          (keyup)="
                                            SearchClientName(clientName.value)
                                          "
                                          class="form-control searchboxnew"
                                          placeholder="{{
                                            'account.filterByLocation'
                                              | cxTranslate
                                          }}	"
                                        />
                                      </div>
                                    </section>
                                    <section>
                                      <section
                                        [ngClass]="{
                                          displayNone:
                                            searchedClientList?.length === 0
                                        }"
                                        class="custom-radio radio_grp label-location w-100 mt-4"
                                      >
                                        <table
                                          aria-describedby="shiptolocation"
                                          *ngFor="
                                            let data of searchedClientList;
                                            let i = index
                                          "
                                          class="w-100 mt-2"
                                        >
                                          <tr
                                            class="line-location"
                                            [ngClass]="{
                                              'label-location':
                                                i !==
                                                searchedClientList.length - 1
                                            }"
                                          >
                                            <td class="u-border-top-grey pt-1">
                                              <input
                                                type="radio"
                                                (change)="
                                                  onOptionsSelected(data)
                                                "
                                                [id]="data?.id"
                                                [checked]="
                                                  selectedData?.id == data?.id
                                                "
                                                name="customRadio"
                                                class="custom-control-input"
                                              />
                                              <label
                                                class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                                                [for]="data?.id"
                                              >
                                                {{ data?.companyName }}
                                                {{ '(' + data?.unitID + ')' }}
                                              </label>
                                              <p
                                                class="labelpp mb-2 text-fixed-medium"
                                              >
                                                {{
                                                  data?.line1
                                                    ? data?.line1 + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.line2
                                                    ? data?.line2 + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.town
                                                    ? data?.town + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.region?.isocodeShort
                                                    ? data?.region
                                                        ?.isocodeShort + ' '
                                                    : ''
                                                }}
                                                {{ data?.postalCode }}
                                              </p>
                                            </td>
                                          </tr>
                                        </table>
                                      </section>
                                    </section>
                                  </section>
                                  <section
                                    class="modal-footer justify-content-start"
                                  >
                                    <button
                                      type="button"
                                      class="c-button c-button--primary ml-3"
                                      (click)="onSubmit()"
                                      (click)="modal.close('Save click')"
                                    >
                                      {{ 'account.done' | cxTranslate }}
                                    </button>
                                  </section>
                                </section>
                              </section>
                            </section>
                          </ng-template>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </div>
          </div>
          <div class="col-md-5">
            <section class="control-group">
              <section
                class="controls input-append date cart-delivery-date-picker"
                data-date=""
                data-date-format="mm/dd/yyyy"
                data-link-field="dtp_input2"
                data-link-format="mm/dd/yyyy"
              >
                <span class="add-on"><i class="icon-th"></i></span>
                <input
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="delivery-pickup-calendar"
                  bsDatepicker
                  [(ngModel)]="deliveryDate"
                  [daysDisabled]="[0, 6]"
                  [minDate]="minDeliveryDate"
                  [maxDate]="maxDeliveryDate"
                  (bsValueChange)="onDeliveryDateChange($event)"
                  [bsConfig]="{
                    containerClass: 'theme-green deliveryPickup'
                  }"
                />
              </section>

              <p class="text-fixed-legal-copy mt-2">
                {{ 'addToCartItems.deliveryMsg' | cxTranslate }}
              </p>
            </section>
          </div>
        </div>
      </div>
      <!-- List of Products -->
      <section id="pdp-list1" class="panel-collapse mr-0 mr-sm-4">
        <section class="panel-body no-location-panel">
          <div
            *ngFor="let productentry of productEntries"
            class="pdp-list-data row"
          >
            <div class="col-md-4 col-lg-2">
              <a (click)="productDetails(productentry)">
                <img
                  class="img-fluid"
                  src="{{
                    productentry.PRIMARY
                      ? productentry?.PRIMARY?.url
                      : fallbackImage
                  }}"
                  alt=""
                />
              </a>

              <div class="hidden-xs delete-popover-container no-print pt-2">
                <ng-template #popTitleDelete>
                  {{ 'addToCartItems.deleteTitle' | cxTranslate }}
                </ng-template>
                <ng-template #popContentDelete>
                  <div class="c-popover">
                    <button
                      type="reset"
                      class="popover-close popover-cancel-button c-button c-button--secondary mb-2"
                      data-di-id="di-id-d01008be-fb6e135c"
                      (click)="toggle(p1)"
                    >
                      {{ 'addToCartItems.cancel' | cxTranslate }}
                    </button>
                    <button
                      class="popover-cancel-button c-button c-button--primary mb-2"
                      type="submit"
                      data-di-id="di-id-3064f17d-e97cc628"
                      (click)="
                        deleteCart(
                          productentry?.entryNumber,
                          productentry?.product?.name
                        )
                      "
                    >
                      {{ 'addToCartItems.delete' | cxTranslate }}
                    </button>
                  </div>
                </ng-template>
                <a
                  [ngbPopover]="popContentDelete"
                  [popoverTitle]="popTitleDelete"
                  triggers="manual"
                  #p1="ngbPopover"
                  placement="top"
                  class="trash text-fixed-legal-copy color-grey-light"
                  (click)="toggle(p1)"
                  href="javascript:;"
                >
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                  <span class="d-none d-md-inline">{{
                    'addToCartItems.delete' | cxTranslate
                  }}</span>
                </a>
              </div>
            </div>

            <div class="col-md-8 col-lg-5">
              <a (click)="productDetails(productentry)">
                <h2
                  [innerHTML]="productentry.product.name"
                  class="c-heading c-heading-h4 mb-4"
                ></h2>
              </a>
              <p
                [innerHTML]="productentry.product.description"
                class="text-fixed-medium color-grey"
              ></p>

              <p class="text-fixed-legal-copy color-grey-dark mb-1">
                <span class="font-bold">{{
                  'plp.productNumber' | cxTranslate
                }}</span
                >: &nbsp;{{ productentry?.product?.code }}
              </p>
              <p
                class="text-fixed-legal-copy color-grey-dark"
                *ngIf="productentry?.product?.actualPotency"
              >
                <span class="font-bold">{{
                  'plp.actualPotency' | cxTranslate
                }}</span
                >:&nbsp;

                {{ productentry.product.actualPotency }}
              </p>
            </div>

            <div class="col-xs-6 col-md-6 col-lg-3 price-list">
              <h6
                class="text-fixed-legal-copy color-grey-dark font-weight-bold"
                *ngIf="
                  productentry.product.actualPotency;
                  else notActualPotency
                "
              >
                {{ 'plp.your_price' | cxTranslate }}
              </h6>
              <ng-template #notActualPotency>
                <h6
                  class="text-fixed-legal-copy color-grey-dark font-weight-bold"
                >
                  {{ 'plp.yourPrice' | cxTranslate }}
                </h6>
              </ng-template>
              <span class="c-heading c-heading-h3">
                {{ productentry?.basePrice?.value | currency }}
              </span>
            </div>

            <div class="col-xs-6 col-md-6 col-lg-2 unit-list">
              <div class="mb-2 text-center font-weight-bold color-grey-dark">
                {{ 'plp.units' | cxTranslate }}
              </div>
              <div class="unit-area d-flex flex-nowrap align-items-center justify-content-center">
                <span
                  id="{{ 'unitMinus' + productentry?.product?.code }}"
                  class="d-none"
                ></span>
                <span
                  id="{{ 'unitMinusDisable' + productentry?.product?.code }}"
                  class="d-none"
                ></span>
                <span
                  id="{{ 'unitPlus' + productentry?.product?.code }}"
                  class="d-none"
                ></span>
                <span
                  id="{{ 'unitPlusDisable' + productentry?.product?.code }}"
                  class="d-none"
                ></span>
                <button
                  id="{{ 'minusIcon' + productentry?.product?.code }}"
                  class="c-count-change-btn"
                  [ngClass]="{ disabled: productentry?.quantity < 2 }"
                  aria-hidden="true"
                  (click)="
                    performQuantityComputation(
                      2,
                      productentry?.product?.code,
                      productentry?.product?.quantityPerUnit,
                      productentry.entryNumber
                    )
                  "
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </button>
                <input
                  type="number"
                  class="prod-quantity text-center"
                  value="{{ productentry?.quantity }}"
                  id="{{ 'prodQuantity' + productentry?.product?.code }}"
                  min="1"
                  max="9999"
                  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                  (keyup)="
                    performQuantityComputation(
                      null,
                      productentry?.product?.code,
                      productentry?.product?.quantityPerUnit,
                      productentry.entryNumber
                    )
                  "
                />
                <button
                  id="{{ 'plusIcon' + productentry?.product?.code }}"
                  class="c-count-change-btn"
                  [ngClass]="{ disabled: productentry?.quantity > 998 }"
                  aria-hidden="true"
                  (click)="
                    performQuantityComputation(
                      1,
                      productentry?.product?.code,
                      productentry?.product?.quantityPerUnit,
                      productentry.entryNumber
                    )
                  "
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
              <ng-container *ngIf="salesOrg$ | async as salesOrg" >
                <div *ngIf="salesOrg != 'CA04'" id="{{ 'noOfDoses' + productentry?.product?.code }}" class='doses-section text-center mt-2 color-grey-dark'>
                  {{ productentry?.product?.quantityPerUnit * productentry?.quantity }}
                  {{ 'plp.doses' | cxTranslate }} 
                </div>
            </ng-container>
            </div>
          </div>
        </section>
      </section>
    </div>
    <!-- Right side -->
    <aside class="col-md-4 col-sm-12 cart-main-page">
      <div
        class="aside-section savings-total"
        aria-label="subTotal"
        [ngClass]="isSticky === true ? 'd-none d-md-block' : ''"
      >
        <h3 class="c-heading c-heading-h3">
          {{ 'addToCartItems.orderSubtotal' | cxTranslate }}
          <span *ngIf="!updateTotal; else noPrice"
            >{{ standardCartDetails?.subTotal?.value | currency }}
          </span>
          <ng-template #noPrice>
            {{ 'addToCartItems.noprice' | cxTranslate }}
          </ng-template>
        </h3>
        <button
          *ngIf="!updateTotal"
          type="button"
          class="c-button c-button--primary mt-3 proceedCheckout no-print"
          (click)="proceedToCheckout()"
          [disabled]="orderBlock || checkoutClicked || yfEntry"
          [ngClass]="orderBlock || yfEntry ? 'disableCTA' : ''"
        >
          <i class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></i>
          {{ 'addToCartItems.proceedToCheckout' | cxTranslate }}
        </button>
        <button
          *ngIf="updateTotal"
          class="c-button c-button--secondary mt-3 updateOrder"
          (click)="updateCart()"
        >
          {{ 'addToCartItems.updateOrder' | cxTranslate }}
        </button>
      </div>
      <div class="c-widget c-quick-order-widget" aria-label="quickOrderSection">
        <div>
          <h3 class="c-widget-title c-heading c-heading-h3">
            {{ 'addToCartItems.addProducts' | cxTranslate }}
          </h3>
          <h6 class="c-heading c-heading-h5 color-secondary">
            {{ 'addToCartItems.quickOrder' | cxTranslate }}
          </h6>
          <p class="text-fixed-legal-copy">
            {{ 'addToCartItems.searchItems' | cxTranslate }}
          </p>
        </div>
        <app-quick-order class="w-100"></app-quick-order>
      </div>
    </aside>
  </section>
</section>
<ng-template #emptyCart>
  <app-empty-cart compName="EmptyCartParagraphComponent"></app-empty-cart>
</ng-template>
