<div class="row">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <form [formGroup]="recoverPasswordForm">
      <div class="col-md-8 col-lg-5">
        <h2 class="mb-4 c-form-title">
          {{ "forgotUserPassword.recoverUsername" | cxTranslate }}
        </h2>
        <div class="c-text-input">
          <label for="emailAddress" class="c-form-label">{{
            "forgotUserPassword.emailAddress" | cxTranslate
          }}</label>
          <input
            type="text"
            id="email"
            name="email"
            formControlName="email"
            (ngModelChange)="validateRecoverPassword()"
          />

          <cx-form-errors
            class="text-danger"
            *ngIf="
              (recoverPasswordForm.get('email').hasError('required') &&
                recoverPasswordForm.get('email').touched) ||
              (recoverPasswordrequired &&
                !recoverPasswordForm.get('email').hasError('pattern'))
            "
          >
            <p>
              {{ "forgotUserPassword.EmailRequired" | cxTranslate }}
            </p>
          </cx-form-errors>
          <cx-form-errors
            class="text-danger"
            *ngIf="recoverPasswordForm.get('email').hasError('pattern')"
          >
            <p>
              {{ "forgotUserPassword.invalidEmail" | cxTranslate }}
            </p>
          </cx-form-errors>
        </div>
        <div class="mt-4">
          <button
            type="submit"
            class="c-button c-button--primary"
            (click)="submitrecoverPasswordForm()"
          >
            {{ "forgotUserPassword.submit" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
    <hr class="Fields-container-lin my-4" />
    <form [formGroup]="passwordResetForm">
      <div class="col-md-8 col-lg-5">
        <h2 class="mb-4 c-form-title">
          {{ "forgotUserPassword.resetPassword" | cxTranslate }}
        </h2>
        <div class="c-text-input">
          <label id="address2" class="c-form-label">{{
            "forgotUserPassword.emailAddress" | cxTranslate
          }}</label>
          <input
            type="email"
            formControlName="email"
            id="email"
            name="email"
            (ngModelChange)="validatepasswordReset()"
          />
          <cx-form-errors
            class="text-danger"
            *ngIf="
              (passwordResetForm.get('email').hasError('required') &&
                passwordResetForm.get('email').touched) ||
              (passwordResetRequired &&
                !passwordResetForm.get('email').hasError('pattern'))
            "
          >
            <p>
              {{ "forgotUserPassword.EmailRequired" | cxTranslate }}
            </p>
          </cx-form-errors>
          <cx-form-errors
            class="text-danger"
            *ngIf="passwordResetForm.get('email').hasError('pattern')"
          >
            <p>
              {{ "forgotUserPassword.invalidEmail" | cxTranslate }}
            </p>
          </cx-form-errors>
          <div class="mt-4">
            <button
              type="submit"
              class="c-button c-button--primary"
              (click)="submitpasswordResetForm()"
            >
              {{ "forgotUserPassword.continue" | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
